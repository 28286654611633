import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { deleteUserAsyncThunk, getAllUsersPaginateAsyncThunk } from '../../store/user/userSlice';

export default function DeleteDialog({ show, hideDialog, onDeleteUser, userId, userName }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const deleteUser = async () => {
        try {
            setLoading(true);
            await dispatch(deleteUserAsyncThunk(userId)).unwrap();
            await dispatch(
                getAllUsersPaginateAsyncThunk({
                    page: 0,
                    limit: 10,
                    sortField: null,
                    sortOrder: null,
                    filters: {
                        name: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
                        email: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] }
                    }
                })
            );
            onDeleteUser();
        } catch (err) {
            // Error will be handled by the Redux state and parent component
        } finally {
            setLoading(false);
        }
    };

    const deleteUserDialogFooter = (
        <React.Fragment>
            <Button label="Hayır" icon="pi pi-times" outlined onClick={hideDialog} disabled={loading} />
            <Button label="Evet" icon="pi pi-check" severity="danger" onClick={deleteUser} loading={loading} />
        </React.Fragment>
    );

    return (
        <Dialog visible={show} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Silme" modal footer={deleteUserDialogFooter} onHide={hideDialog}>
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                <span>
                    <b>{userName}</b> Silmek istediğinize emin misiniz ?
                </span>
            </div>
        </Dialog>
    );
}

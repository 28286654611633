import axios from 'axios';
import { users } from '../url';

export const getAllUsers = async () => {
    return await axios.get(users.getAllUsers);
};

export const getCompaniesByUserId = async (id) => {
    return await axios.post(users.getCompaniesByUserId, { userId: id });
};

export const createUser = async (user) => {
    return await axios.post(users.createUser, user);
};

export const updateUser = async (user) => {
    return await axios.put(users.updateUser, user);
};

export const deleteUser = async (id) => {
    return await axios.delete(users.deleteUser + `?id=${id}`);
};

export const setRoleFromUser = async (data) => {
    return await axios.post(users.setRole, data);
};

export const deleteRoleFromUser = async (data) => {
    return await axios.delete(users.deleteRole + `?userId=${data.userId}&roleId=${data.roleId}`);
};

export const getRoleAndPermissions = async (userId, roleId) => {
    return await axios.post(users.getRoleAndPermissions + `?userId=${userId}&roleId=${roleId}`);
};

export const getByEmail = async (email) => {
    return await axios.get(users.getByEmail + `?email=${email}`);
};

export const getAllUsersPaginate = async (data) => {
    return await axios.get(users.getAllPaginate, {
        params: {
            ...data
        }
    });
};

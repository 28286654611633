import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getAllCompanies,
    createCompany,
    updateCompany,
    deleteCompany,
    attachUser,
    detachUser,
    attachModule,
    detachModule,
    getModulesByCompany,
    getCompanySettings,
    setCompanySettings,
    getAllCompaniesPaginate
} from '../../service/request/companyRequest';
import { deleteModule } from '../../service/request/moduleRequest';

export const getAllCompaniesAsyncThunk = createAsyncThunk('company/getAllCompanies', async () => {
    const response = await getAllCompanies();
    return response.data;
});

export const getAllCompaniesPaginateAsyncThunk = createAsyncThunk('company/getAllCompaniesPaginate', async (data) => {
    const response = await getAllCompaniesPaginate(data);
    return response.data;
});

export const getModulesByCompanyAsyncThunk = createAsyncThunk('company/getModulesByCompany', async (id) => {
    const response = await getModulesByCompany(id);
    return response.data;
});

export const createCompanyAsyncThunk = createAsyncThunk('company/createCompany', async (data) => {
    const response = await createCompany(data);
    return response.data;
});

export const updateCompanyAsyncThunk = createAsyncThunk('company/updateCompany', async (data) => {
    const response = await updateCompany(data);
    return response.data;
});

export const deleteCompanyAsyncThunk = createAsyncThunk('company/deleteCompany', async (id) => {
    const response = await deleteCompany(id);
    return id;
});

export const attachModuleAsyncThunk = createAsyncThunk('company/attachModule', async (data) => {
    try {
        const response = await attachModule(data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.message);
    }
});

export const detachModuleAsyncThunk = createAsyncThunk('company/detachModule', async (data) => {
    const response = await detachModule(data);
    return response.data;
});

export const setCompanySettingsAsyncThunk = createAsyncThunk('company/setCompanySettings', async (data) => {
    const response = await setCompanySettings(data);
    return response.data;
});

export const getCompanySettingsAsyncThunk = createAsyncThunk('company/getCompanySettings', async (data) => {
    const response = await getCompanySettings(data);
    return response.data;
});

export const deleteModuleAsyncThunk = createAsyncThunk('company/deleteModule', async (data) => {
    const response = await deleteModule(data);
    return data.module_id;
});

export const companySlice = createSlice({
    name: 'company',
    initialState: {
        companyList: {
            data: [],
            total: 0,
            per_page: 10,
            current_page: 1,
            from: 0,
            to: 0
        },
        companyListFull: [],
        status: null,
        error: null,
        toastMessage: null,
        modules: [],
        totalRecords: 0,
        totalResponse: null
    },
    reducers: {
        clearToast: (state) => {
            state.status = null;
            state.error = null;
            state.toastMessage = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAllCompaniesAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllCompaniesAsyncThunk.fulfilled, (state, action) => {
                state.status = '';
                state.companyListFull = action.payload.data;
            })
            .addCase(getAllCompaniesAsyncThunk.rejected, (state) => {
                state.status = 'failed';
                state.toastMessage = 'Şirketler getirilirken hata oluştu.';
            })
            .addCase(getAllCompaniesPaginateAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllCompaniesPaginateAsyncThunk.fulfilled, (state, action) => {
                state.companyList = action.payload.data;
                state.status = '';
                state.error = null;
                state.toastMessage = 'Şirketler başarıyla getirildi.';
            })
            .addCase(getAllCompaniesPaginateAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createCompanyAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList.data = [...state.companyList.data, action.payload.data];
                state.toastMessage = 'Şirket başarıyla oluşturuldu.';
            })
            .addCase(createCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
                state.toastMessage = 'Şirket oluşturulurken hata oluştu.';
            })
            .addCase(updateCompanyAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList.data = state.companyList.data.map((company) => (company.id === action.payload.data.id ? action.payload.data : company));
                state.toastMessage = 'Şirket başarıyla güncellendi.';
            })
            .addCase(updateCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
                state.toastMessage = 'Şirket güncellenirken hata oluştu.';
            })
            .addCase(deleteCompanyAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companyList.data = state.companyList.data.filter((company) => company.id !== action.payload);
                state.toastMessage = 'Şirket başarıyla silindi.';
            })
            .addCase(deleteCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
                state.toastMessage = 'Şirket silinirken hata oluştu.';
            })
            .addCase(getModulesByCompanyAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.modules = action.payload.data;
                state.toastMessage = 'Şirket modülleri başarıyla getirildi.';
            })
            .addCase(getModulesByCompanyAsyncThunk.rejected, (state) => {
                state.status = 'failed';
                state.toastMessage = 'Şirket modülleri getirilirken hata oluştu.';
            })
            .addCase(setCompanySettingsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companySettings = action.payload.data;
            })
            .addCase(setCompanySettingsAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(getCompanySettingsAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.companySettings = action.payload.data;
            })
            .addCase(getCompanySettingsAsyncThunk.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(attachModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.modules = [...state.modules, action.payload.data];
                state.toastMessage = 'Modül başarıyla eklendi.';
            })
            .addCase(attachModuleAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                if (action.error.message === 'Module already added to company') {
                    state.toastMessage = 'Modül zaten tanımlı.';
                } else {
                    state.toastMessage = 'Modül eklenirken hata oluştu.';
                }
            })
            .addCase(deleteModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.modules = state.modules.filter((module) => module.id !== action.payload);
                state.toastMessage = 'Modül başarıyla silindi.';
            })
            .addCase(deleteModuleAsyncThunk.rejected, (state) => {
                state.status = 'failed';
                state.toastMessage = 'Modül silinirken hata oluştu.';
            });
    }
});

export const { clearToast } = companySlice.actions;
export default companySlice.reducer;

const BASE_URL = process.env.REACT_APP_SWAGGER_URL;

export const auth = {
    login: BASE_URL + '/login',
    logout: BASE_URL + '/auth/logout',
    register: BASE_URL + '/auth/register'
};

export const uyumsoft = {
    getUyumTaxId: BASE_URL + '/company/getUyumTaxId'
};

export const users = {
    getAllUsers: BASE_URL + '/user/getAll',
    getCompaniesByUserId: BASE_URL + '/user/getCompaniesByUserId',
    createUser: BASE_URL + '/user/create',
    updateUser: BASE_URL + '/user/update',
    deleteUser: BASE_URL + '/user/delete',
    setRole: BASE_URL + '/user/role/setRole',
    getRole: BASE_URL + '/user/role/getRole',
    deleteRole: BASE_URL + '/user/role/deleteRole',
    getRoleAndPermissions: BASE_URL + '/user/role/getRoleAndPermissions',
    getByEmail: BASE_URL + '/user/getByEmail',
    getAllPaginate: BASE_URL + '/user/getAllPaginate'
};

export const companies = {
    getAllCompanies: BASE_URL + '/company/getAll',
    getModulesByCompany: BASE_URL + '/company/module/getByCompanyModules',
    createCompany: BASE_URL + '/company/create',
    updateCompany: BASE_URL + '/company/update',
    deleteCompany: BASE_URL + '/company/delete',
    attachUser: BASE_URL + '/company/addUser',
    detachUser: BASE_URL + '/company/removeUser',
    attachModule: BASE_URL + '/company/module/addModule',
    detachModule: BASE_URL + '/company/module/removeModule',
    setCompanySettings: BASE_URL + '/company/module/setModuleSetting',
    getCompanySettings: BASE_URL + '/company/module/getModuleSetting',
    getCompaniesModules: BASE_URL + '/company/module/getCompaniesModules',
    getLucaCompanies: BASE_URL + '/company/getLucaCompanies',
    getAllPaginate: BASE_URL + '/company/getAllPaginate'
};

export const modules = {
    getAllModules: BASE_URL + '/module/getAll',
    addLuca: BASE_URL + '/module/edefter/addLuca',
    updateLuca: BASE_URL + '/module/edefter/updateLuca',
    deleteModule: BASE_URL + '/company/module/removeModule',
    getAllCommercialProgramsByModuleId: BASE_URL + '/module/getPrograms',
    getAllWebServicesByModuleId: BASE_URL + '/module/getWebServices',

    addParasut: BASE_URL + '/module/efatura/addParasut',
    updateParasut: BASE_URL + '/module/efatura/updateParasut',

    addRapid: BASE_URL + '/module/efatura/addRapid',
    updateRapid: BASE_URL + '/module/efatura/updateRapid',

    addDatasoft: BASE_URL + '/module/edefter/addDatasoft',
    updateDatasoft: BASE_URL + '/module/edefter/updateDatasoft'
};

export const roles = {
    getAllRoles: BASE_URL + '/roles/getAll',
    createRole: BASE_URL + '/roles/create',
    updateRole: BASE_URL + '/roles/update',
    deleteRole: BASE_URL + '/roles/delete',
    getPermissions: BASE_URL + '/roles/getPermissions',
    setPermissions: BASE_URL + '/roles/setPermissions',
    getAllPermissions: BASE_URL + '/roles/getAllPermissions'
};

export const tickets = {
    getAll: BASE_URL + '/ticket/getAll',
    sendMessage: BASE_URL + '/ticket/sendMessage',
    getById: BASE_URL + '/ticket/getById',
    updateStatus: BASE_URL + '/ticket/updateStatus'
};

export const education = {
    getAll: BASE_URL + '/education/category/getAll',
    getById: BASE_URL + '/education/category/getById',
    create: BASE_URL + '/education/category/create',
    update: BASE_URL + '/education/category/update',
    delete: BASE_URL + '/education/category/delete',
    getAllVideos: BASE_URL + '/education/videos/getAll',
    getVideoById: BASE_URL + '/education/videos/getById',
    createVideo: BASE_URL + '/education/videos/create',
    updateVideo: BASE_URL + '/education/videos/update',
    deleteVideo: BASE_URL + '/education/videos/delete'
};

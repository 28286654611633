import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllUsers, createUser, deleteUser, updateUser, getCompaniesByUserId, setRoleFromUser, deleteRoleFromUser, getAllUsersPaginate } from '../../service/request/userRequest';
import { deleteModule } from '../../service/request/moduleRequest';
import { attachUser, detachUser } from '../../service/request/companyRequest';

export const getAllUsersAsyncThunk = createAsyncThunk('user/getAllUsers', async () => {
    const response = await getAllUsers();
    return response.data;
});

export const getAllUsersPaginateAsyncThunk = createAsyncThunk('user/getAllUsersPaginate', async (data) => {
    const response = await getAllUsersPaginate(data);
    return response.data;
});

export const getCompaniesByUserIdAsyncThunk = createAsyncThunk('user/getCompaniesByUserId', async (id) => {
    const response = await getCompaniesByUserId(id);
    return response.data;
});

export const createUserAsyncThunk = createAsyncThunk('user/createUser', async (user) => {
    const response = await createUser(user);
    return response.data;
});

export const updateUserAsyncThunk = createAsyncThunk('user/updateUser', async (user) => {
    const response = await updateUser(user);
    return response.data;
});

export const deleteUserAsyncThunk = createAsyncThunk('user/deleteUser', async (id) => {
    const response = await deleteUser(id);
    return id;
});

export const deleteModuleAsyncThunk = createAsyncThunk('modules/deleteModule', async (data) => {
    const response = await deleteModule(data);
    return response.data;
});

export const attachUserAsyncThunk = createAsyncThunk('company/attachUser', async (data) => {
    const response = await attachUser(data);
    return response.data;
});

export const detachUserAsyncThunk = createAsyncThunk('company/detachUser', async (data) => {
    const response = await detachUser(data);
    return response.data;
});

export const setRoleFromUserAsyncThunk = createAsyncThunk('roles/setRoleFromUser', async (data) => {
    const response = await setRoleFromUser(data);
    return response.data;
});

export const deleteRoleFromUserAsyncThunk = createAsyncThunk('roles/deleteRoleFromUser', async (data) => {
    const response = await deleteRoleFromUser(data);
    return response.data;
});

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        users: {
            data: [],
            total: 0,
            per_page: 10,
            current_page: 1,
            from: 0,
            to: 0
        },
        status: null,
        error: null,
        toastMessage: null,
        userCompanies: []
    },
    reducers: {
        clearToast: (state) => {
            state.status = null;
            state.error = null;
            state.toastMessage = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getAllUsersAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllUsersAsyncThunk.fulfilled, (state, action) => {
                state.status = '';
                state.error = null;
            })
            .addCase(getAllUsersAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(getAllUsersPaginateAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAllUsersPaginateAsyncThunk.fulfilled, (state, action) => {
                state.users = action.payload.data;
                state.status = '';
                state.error = null;
            })
            .addCase(getAllUsersPaginateAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(createUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createUserAsyncThunk.fulfilled, (state, action) => {
                state.error = null;
                state.status = 'success';
                state.toastMessage = 'Kullanıcı başarıyla eklendi.';
            })
            .addCase(createUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Kullanıcı eklenirken bir hata oluştu.';
            })

            .addCase(updateUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                state.toastMessage = 'Kullanıcı başarıyla güncellendi.';
            })
            .addCase(updateUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Kullanıcı güncellenirken bir hata oluştu.';
            })

            .addCase(deleteUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                state.toastMessage = 'Kullanıcı başarıyla silindi.';
            })
            .addCase(deleteUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Kullanıcı silinirken bir hata oluştu.';
            })

            .addCase(getCompaniesByUserIdAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCompaniesByUserIdAsyncThunk.fulfilled, (state, action) => {
                state.userCompanies = action.payload.data;
            })
            .addCase(getCompaniesByUserIdAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })

            .addCase(deleteModuleAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteModuleAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                state.toastMessage = 'Modül başarıyla silindi.';
                state.userCompanies = state.userCompanies.map((company) => (company._id === action.payload.data._id ? action.payload.data : company));
            })
            .addCase(deleteModuleAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Modül silinirken bir hata oluştu.';
            })

            .addCase(setRoleFromUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(setRoleFromUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                state.toastMessage = 'Rol kullanıcıya başarıyla eklendi.';
            })
            .addCase(setRoleFromUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol eklenirken bir hata oluştu.';
            })

            .addCase(deleteRoleFromUserAsyncThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteRoleFromUserAsyncThunk.fulfilled, (state, action) => {
                state.status = 'success';
                state.error = null;
                state.toastMessage = 'Rol kullanıcıdan başarıyla silindi.';
            })
            .addCase(deleteRoleFromUserAsyncThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
                state.toastMessage = 'Rol silinirken bir hata oluştu.';
            });
    }
});

export const { clearToast } = userSlice.actions;
export default userSlice.reducer;

import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { getFilterCompany } from '../utils/util';
import { getAllCompaniesAsyncThunk } from '../../../store/company/companySlice';
import { MultiSelect } from 'primereact/multiselect';

export default function AddCompanyDialog({ visible, selectedUser, onHide, onSubmit }) {
    const { companyListFull } = useSelector((state) => state.company);
    const [selectedCompanies, setSelectedCompanies] = useState(null);
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchCompanies = async () => {
            if (visible) {
                setLoading(true);
                if (!companyListFull?.data || companyListFull.data.length === 0) {
                    const response = await dispatch(getAllCompaniesAsyncThunk());

                    if (response.payload?.data) {
                        setCompanies(response.payload.data);
                        setLoading(false);
                    }
                } else {
                    setCompanies(companyListFull.data);
                    setLoading(false);
                }
            } else {
                setSelectedCompanies(null);
            }
        };

        fetchCompanies();
    }, [visible, dispatch]);

    useEffect(() => {
        if (companyListFull?.data && companyListFull.data.length > 0) {
            setCompanies(companyListFull.data);
            setLoading(false);
        }
    }, [companyListFull]);

    const dialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={() => onHide()} />
            <Button label="Bağla" icon="pi pi-user-plus" onClick={(e) => handleSubmit()} type="submit" disabled={!selectedCompanies} />
        </React.Fragment>
    );

    const handleSubmit = () => {
        onSubmit(selectedCompanies);
        setSelectedCompanies(null);
    };

    const getFilteredCompanies = () => {
        const userComp = selectedUser?.companies;
        const copy = [];

        companies.forEach((company) => {
            console.log('Processing company:', company);
            if (!userComp?.some((comp) => comp.id === company.id)) {
                copy.push({ label: company.name.length > 90 ? company.name.substring(0, 90) + '...' : company.tax_number + ' - ' + company.name, value: company.id });
            }
        });

        return copy;
    };

    if (!visible) {
        return null;
    }

    return (
        <Dialog visible={visible} style={{ width: '62rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Şirket Bağla" modal className="p-fluid" footer={dialogFooter} onHide={() => onHide()}>
            <div className="field">
                <label htmlFor="Şirket Listesi" className="font-bold">
                    Şirket Listesi
                </label>
                <MultiSelect
                    filter
                    emptyFilterMessage="Şirket bulunamadı"
                    emptyMessage="Şirket bulunamadı"
                    placeholder={loading ? 'Şirketler Getiriliyor...' : 'Şirket Seçiniz'}
                    display="chip"
                    value={selectedCompanies}
                    onChange={(e) => setSelectedCompanies(e.value)}
                    options={getFilteredCompanies()}
                    optionLabel="label"
                    disabled={loading}
                />
            </div>
        </Dialog>
    );
}

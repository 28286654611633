import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { getAllUsersPaginateAsyncThunk, updateUserAsyncThunk } from '../../store/user/userSlice';

export default function EditDialog({ show, selectedUser, hideDialog, onEditUser }) {
    const [submitted, setSubmitted] = useState(false);
    const [selected, setSelected] = useState({});
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const options = [
        { label: 'Admin', value: 'admin' },
        { label: 'User', value: 'user' }
    ];

    useEffect(() => {
        if (selectedUser) {
            setSelected(selectedUser);
        }
    }, [selectedUser]);

    const onInputChange = (e, name) => {
        const val = e.target.value;
        let _user = { ...selected };
        _user[`${name}`] = val;
        setSelected(_user);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selected.name.trim()) {
            setSubmitted(true);
            return;
        }
        if (!selected.email.trim()) {
            setSubmitted(true);
            return;
        }
        /* if (!selected.role.trim()) {
            setSubmitted(true);
            return;
        } */
        if (selected.password && selected.password.length < 6) {
            setSubmitted(true);
            return;
        }

        setLoading(true);
        onEditUser();
        await dispatch(
            updateUserAsyncThunk({
                name: selected.name,
                email: selected.email,
                /* role: selected.role, */
                id: selected.id,
                password: selected.password === '' ? null : selected.password
            })
        );
        await dispatch(
            getAllUsersPaginateAsyncThunk({
                page: 0,
                limit: 10,
                sortField: null,
                sortOrder: null,
                filters: {
                    name: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
                    email: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] }
                }
            })
        );
        setLoading(false);
        setSelected({});
        setSubmitted(false);
    };

    const userDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" outlined onClick={hideDialog} disabled={loading} />
            <Button label="Kaydet" icon="pi pi-check" onClick={(e) => handleSubmit(e)} type="submit" loading={loading} />
        </React.Fragment>
    );

    if (!selectedUser) {
        return null;
    }

    return (
        <Dialog visible={show} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Kullanıcı Güncelle" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label htmlFor="Kullanıcı Adı" className="font-bold">
                        Kullanıcı Adı
                    </label>
                    <InputText id="name" value={selected.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !selected.name })} placeholder="Kullanıcı Adı" />
                    {submitted && !selected.name && <small className="p-error">Kullanıcı Adı gerekli.</small>}
                </div>
                <div className="field">
                    <label htmlFor="E-Mail" className="font-bold">
                        E-Mail
                    </label>
                    <InputText id="E-Mail" value={selected.email} onChange={(e) => onInputChange(e, 'email')} required className={classNames({ 'p-invalid': submitted && !selected.email })} placeholder="E-Mail" />
                    {submitted && !selected.email && <small className="p-error">Email gerekli.</small>}
                </div>

                <div className="field">
                    <label htmlFor="Şifre" className="font-bold">
                        Şifre
                    </label>
                    <InputText id="Şifre" value={selected.password} onChange={(e) => onInputChange(e, 'password')} required placeholder="Şifre" className={classNames({ 'p-invalid': submitted && !selected.password })} />
                    {submitted && selected.password.length > 0 && selected.password.length < 6 && <small className="p-error">Şifre 5 karakterden uzun olmalıdır.</small>}
                </div>
                {/* <div className="field">
                    <label htmlFor="Rol" className="font-bold">
                        Rol
                    </label>
                    <Dropdown
                        id="Rol"
                        value={selected.role}
                        options={options}
                        onChange={(e) => onInputChange(e, 'role')}
                        placeholder="Rol"
                        filter
                        className={classNames({ 'p-invalid': submitted && !selected.role })}
                    />
                    {submitted && !selected.role && <small className="p-error">Rol gerekli.</small>}
                </div> */}
            </form>
        </Dialog>
    );
}

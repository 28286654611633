import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { debounce } from 'lodash';

import { useSelector, useDispatch } from 'react-redux';
import { getAllCompaniesAsyncThunk, getModulesByCompanyAsyncThunk, clearToast, attachModuleAsyncThunk, getAllCompaniesPaginateAsyncThunk } from '../../store/company/companySlice';

import DeleteDialog from './DeleteDialog';
import AddDialog from './AddDialog';
import EditDialog from './EditDialog';

import { setRequirements, setUpdatedModule, setSelectedCompanyAction } from '../../store/modules/moduleSlice';
import { useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';

import { usePermissionContext } from '../../context/usePermissionContext';

import { programTemplate } from './utils/utils';

import AttachModuleDialog from './components/AttachModuleDialog';
import DeleteModuleDialog from './components/DeleteModuleDialog';
import AddProgramDialog from './components/AddProgramDialog';
import Loading from '../module/datasoft/components/Loading';
import { getAllUsersAsyncThunk } from '../../store/user/userSlice';
import { Dropdown } from 'primereact/dropdown';
import { getAllUsers } from '../../service/request/userRequest';
import CommonDeleteDialog from '../user/components/CommonDeleteDialog';
import { attachUser, detachUser } from '../../service/request/companyRequest';

export default function ProductsDemo() {
    const { companyList, status, toastMessage, modules, totalRecords, totalResponse } = useSelector((state) => state.company);
    const [filters, setFilters] = useState({
        name: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
        tax_number: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
        tax_office: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
        phone: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
        address: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [lazyState, setLazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
            tax_number: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
            tax_office: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
            email: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] },
            address: { operator: FilterOperator.AND, constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }] }
        }
    });

    const [companyDialog, setCompanyDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);

    const [deleteCompanyDialog, setDeleteCompanyDialog] = useState(false);
    const [addProgramDialog, setAddProgramDialog] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyModuleDialog, setSelectedCompanyModuleDialog] = useState(false);
    const [attachModuleDialog, setAttachModuleDialog] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);
    const [deleteModuleDialog, setDeleteModuleDialog] = useState(false);
    const [userDialogVisible, setUserDialogVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [attachCompanyDialog, setAttachCompanyDialog] = useState(false);
    const [deleteDialogData, setDeleteDialogData] = useState(null);
    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(false);

    const { controlPermission } = usePermissionContext();

    const toast = useRef(null);
    const dt = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const debouncedLoadData = useCallback(
        debounce((newState) => {
            const queryParams = {
                page: newState.page,
                limit: newState.rows,
                sortField: newState.sortField,
                sortOrder: newState.sortOrder,
                filters: newState.filters
            };
            dispatch(getAllCompaniesPaginateAsyncThunk(queryParams));
        }, 500),
        []
    );

    useEffect(() => {
        if (status === 'success') {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: toastMessage, life: 2000 });
            dispatch(clearToast());
        } else if (status === 'failed') {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: toastMessage, life: 2000 });
            dispatch(clearToast());
        }
    }, [status, toastMessage, dispatch]);

    useEffect(() => {
        getAllUsers().then((response) => {
            setUsersList(response.data.data);
        });
    }, []);

    useEffect(() => {
        debouncedLoadData(lazyState);
        return () => debouncedLoadData.cancel();
    }, [lazyState, debouncedLoadData]);

    const onPage = (event) => {
        setLazyState(event);
    };

    const onSort = (event) => {
        setLazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyState(event);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...lazyState.filters };

        setLazyState((prevState) => ({
            ...prevState,
            first: 0,
            filters: _filters
        }));
        setGlobalFilterValue(value);
    };

    const handleGlobalKeyDown = (e) => {
        if (e.key === 'Enter') {
            setLazyState((prevState) => ({
                ...prevState,
                first: 0,
                page: 0,
                filters: filters
            }));
        }
    };

    const handleFilterChange = (field, value) => {
        let _filters = { ...filters };
        _filters[field].constraints[0].value = value;

        setFilters(_filters);
        setLazyState((prevState) => ({
            ...prevState,
            first: 0,
            page: 0,
            filters: _filters
        }));
    };

    const handleFilterKeyDown = (e, field, value) => {
        if (e.key === 'Enter') {
            let _filters = { ...filters };
            _filters[field].constraints[0].value = value;

            setLazyState((prevState) => ({
                ...prevState,
                first: 0,
                page: 0,
                filters: _filters
            }));
        }
    };

    const leftToolbarTemplate = () => {
        if (!controlPermission('company.create')) return null;

        return <Button className="text-white mr-4" label="Şirket Ekle" icon="pi pi-plus" severity="success" onClick={() => setCompanyDialog(true)} />;
    };

    const handleModuleDialog = (rowData) => {
        setAttachModuleDialog(true);
        setSelectedCompany(rowData);
    };

    const handleCompanyModuleDialog = async (rowData) => {
        await dispatch(getModulesByCompanyAsyncThunk(rowData.id));
        setSelectedCompany(rowData);
        setSelectedCompanyModuleDialog(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {controlPermission('company.update') && (
                    <Button
                        icon="pi pi-pencil"
                        tooltip="Düzenle"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        onClick={() => {
                            setSelectedCompany(rowData);
                            setEditDialog(true);
                        }}
                    />
                )}
                {controlPermission('company.module.addModule') && (
                    <Button icon="pi pi-tag" tooltip="Bağla" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="mx-2 text-white" rounded severity="success" onClick={() => handleModuleDialog(rowData)} />
                )}
                {controlPermission('company.module.getByCompanyModules') && (
                    <Button
                        icon="pi pi-briefcase"
                        tooltip="Bağlı Modüller"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        className="mr-2 border-bluegray-500 bg-bluegray-500 text-white"
                        onClick={() => handleCompanyModuleDialog(rowData)}
                    />
                )}
                <Button
                    icon="pi pi-users"
                    tooltip="Bağlı Kullanıcılar"
                    tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                    rounded
                    className="mr-2 border-cyan-400 bg-cyan-400 text-white"
                    onClick={() => {
                        setSelectedCompany(rowData);
                        setUserDialogVisible(true);
                    }}
                />
                {controlPermission('company.delete') && (
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setSelectedCompany(rowData);
                            setDeleteCompanyDialog(true);
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const renderHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
                <h4 className="m-0">Şirketler</h4>
                <div className="flex gap-4">
                    {/* <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Ara..." className="p-inputtext-sm" />
                    </span> */}
                    {leftToolbarTemplate()}
                </div>
            </div>
        );
    };

    const filterClearTemplate = (options) => {
        return (
            <Button
                type="button"
                icon="pi pi-times"
                label="Temizle"
                onClick={() => {
                    let _filters = { ...filters };
                    _filters[options.field] = {
                        operator: FilterOperator.AND,
                        constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }]
                    };
                    setFilters(_filters);
                    setLazyState((prevState) => ({
                        ...prevState,
                        first: 0,
                        page: 0,
                        filters: _filters
                    }));
                }}
                severity="secondary"
                size="small"
                outlined
            />
        );
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" label="Uygula" onClick={options.filterApplyCallback} severity="success" size="small" />;
    };

    const filterHeaderTemplate = () => {
        return <div className=""></div>;
    };

    const addProgram = (rowData) => {
        setAddProgramDialog(true);
        setSelectedModule(rowData);
    };

    const navigateUpdateModule = (rowData) => {
        dispatch(setUpdatedModule(rowData));
        dispatch(setSelectedCompanyAction(selectedCompany));
        const programTitle = rowData.settings.program?.title.split(' ')[0];
        navigate(`/matchmodulesupdate/${rowData.title}/${programTitle}`);
    };

    const detachCompany = async (id) => {
        setLoading(true);
        setUserDialogVisible(false);
        setCompanyDialog(false);
        setDeleteDialogData(null);

        try {
            await detachUser({ companyId: selectedCompany.id, userId: id });
            await dispatch(getAllCompaniesPaginateAsyncThunk());
            await dispatch(getAllUsersAsyncThunk());
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirketten kullanıcı bağlantısı kaldırma işlemi başarılı.', life: 2000 });
            }, 20);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 2000 });
            }, 20);
        }
        setLoading(false);
        setSelectedUser(null);
        setSelectedCompany(null);
    };

    const attachCompanyToUser = async () => {
        setLoading(true);
        setUserDialogVisible(false);
        setAttachCompanyDialog(false);

        try {
            await attachUser({ companyId: [selectedCompany.id], userId: selectedUser });
            await dispatch(getAllCompaniesPaginateAsyncThunk());
            await dispatch(getAllUsersAsyncThunk());
            const updatedUsers = await getAllUsers();
            setUsersList(updatedUsers.data.data);
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirkete kullanıcı bağlama işlemi başarılı.', life: 2000 });
            }, 20);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: err.response.data.message, life: 2000 });
            }, 20);
        }
        setLoading(false);
        setSelectedUser(null);
        setSelectedCompany(null);
    };

    const actionSubBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {!rowData.settings?.program && <Button className="mr-2" icon="pi pi-plus" tooltip="Ekle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded severity="primary" onClick={() => addProgram(rowData)} />}
                {rowData.settings?.program && (controlPermission('company.module.setModuleSetting') || controlPermission('company.module.getModuleSetting')) && (
                    <Button icon="pi pi-sync" tooltip="Güncelle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded severity="success" className="mr-2 text-white" onClick={() => navigateUpdateModule(rowData)} />
                )}
                {controlPermission('company.module.removeModule') && (
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setSelectedModule(rowData);
                            setDeleteModuleDialog(true);
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const actionDetachUserTemplate = (rowData) => {
        return (
            <React.Fragment>
                {controlPermission('users.removeCompany') && (
                    <Button
                        icon="pi pi-trash"
                        tooltip="Sil"
                        tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
                        rounded
                        severity="danger"
                        onClick={() => {
                            setDeleteDialogData({
                                visible: true,
                                header: 'Kullanıcının Bağlantısını Kaldır',
                                submitLabel: 'Bağlantıyı Kaldır',
                                submitIcon: 'pi pi-user-minus',
                                content: rowData.name + ' kullanıcısının bağlantısını kaldırmak istediğinize emin misiniz?',
                                onHide: () => setDeleteDialogData(null),
                                onSubmit: () => detachCompany(rowData.id)
                            });
                        }}
                    />
                )}
            </React.Fragment>
        );
    };

    const navigateProgramPage = (program) => {
        dispatch(setSelectedCompanyAction(selectedCompany));
        dispatch(setRequirements({ programReq: program, companyId: selectedCompany.id, module: selectedModule }));
        const programTitle = program?.title.split(' ')[0];
        navigate(`/matchmodules/${selectedModule.title}/${programTitle}`);
    };

    const attachModuleToCompany = async (moduleId) => {
        setAttachModuleDialog(false);
        setSelectedModule(null);
        await dispatch(attachModuleAsyncThunk({ companyId: selectedCompany.id, moduleId: moduleId }));
    };

    const usersHeader = () => {
        return (
            <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
                <h5 className="m-0">
                    <strong>{selectedCompany?.name}</strong> şirketine bağlı Kullanıcılar
                </h5>
            </div>
        );
    };

    const attachDialogHeaderTemplate = () => {
        return (
            <div className="flex flex-wrap gap-5 align-items-center justify-content-start">
                <Dropdown showClear filter className="w-5" dataKey="id" value={selectedUser} options={usersList} optionLabel="name" optionValue="id" onChange={(e) => setSelectedUser(e.value)} placeholder="Kullanıcı Seç" />
                <Button disabled={!selectedUser} className="text-white mr-4" label="Kullanıcı Bağla" icon="pi pi-plus" severity="success" onClick={() => attachCompanyToUser()} />
            </div>
        );
    };

    if (status === 'loading' || loading) return <Loading />;

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <DataTable
                    ref={dt}
                    value={Array.isArray(companyList?.data) ? companyList.data : []}
                    lazy
                    dataKey="id"
                    paginator
                    first={lazyState.first}
                    rows={companyList?.per_page || 10}
                    totalRecords={companyList?.total || 0}
                    onPage={onPage}
                    onSort={onSort}
                    sortField={lazyState.sortField}
                    sortOrder={lazyState.sortOrder}
                    onFilter={onFilter}
                    filters={lazyState.filters}
                    loading={status === 'loading' || loading}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    currentPageReportTemplate={`Toplam ${companyList?.total || 0} müşteriden ${companyList?.from || 0} - ${companyList?.to || 0} arası gösteriliyor.`}
                    header={renderHeader()}
                    emptyMessage="Şirket Bulunamadı"
                    filterDisplay="menu"
                    showGridlines
                    globalFilterFields={['name', 'tax_number', 'tax_office', 'phone', 'email', 'address']}
                >
                    <Column selectionMode="multiple" exportable={false}></Column>
                    <Column
                        field="name"
                        header="Şirket İsmi"
                        sortable
                        filter
                        filterPlaceholder="Şirket Ara"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showMatchModes={false}
                        showOperator={false}
                        showFilterHeader={false}
                        showFilterOperator={false}
                        filterClear={filterClearTemplate}
                        filterApply={filterApplyTemplate}
                    />
                    <Column
                        field="tax_number"
                        header="Vergi Numarası"
                        sortable
                        filter
                        filterPlaceholder="Vergi No Ara"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showMatchModes={false}
                        showOperator={false}
                        showFilterHeader={false}
                        showFilterOperator={false}
                        filterClear={filterClearTemplate}
                        filterApply={filterApplyTemplate}
                    />
                    <Column
                        field="tax_office"
                        header="Vergi Dairesi"
                        sortable
                        filter
                        filterPlaceholder="Vergi Dairesi Ara"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showMatchModes={false}
                        showOperator={false}
                        showFilterHeader={false}
                        showFilterOperator={false}
                        filterClear={filterClearTemplate}
                        filterApply={filterApplyTemplate}
                    />
                    <Column
                        field="phone"
                        header="Telefon"
                        sortable
                        filter
                        filterPlaceholder="Telefon Ara"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showMatchModes={false}
                        showOperator={false}
                        showFilterHeader={false}
                        showFilterOperator={false}
                        filterClear={filterClearTemplate}
                        filterApply={filterApplyTemplate}
                    />
                    <Column
                        field="email"
                        header="E-Mail"
                        sortable
                        filter
                        filterPlaceholder="Email Ara"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showMatchModes={false}
                        showOperator={false}
                        showFilterHeader={false}
                        showFilterOperator={false}
                        filterClear={filterClearTemplate}
                        filterApply={filterApplyTemplate}
                    />
                    <Column
                        field="address"
                        header="Adres"
                        sortable
                        filter
                        filterPlaceholder="Adres Ara"
                        showFilterMatchModes={false}
                        showAddButton={false}
                        showMatchModes={false}
                        showOperator={false}
                        showFilterHeader={false}
                        showFilterOperator={false}
                        filterClear={filterClearTemplate}
                        filterApply={filterApplyTemplate}
                    />
                    <Column header="İşlemler" body={actionBodyTemplate} exportable={false} style={{ minWidth: '16rem' }} frozen alignFrozen="right"></Column>
                </DataTable>

                <Dialog header="Şirket Modülleri" visible={selectedCompanyModuleDialog} style={{ width: '40vw' }} modal contentStyle={{ height: '150px' }} onHide={() => setSelectedCompanyModuleDialog(false)}>
                    <DataTable dataKey="id" emptyMessage="Modül Bulunamadı" value={modules || []} className="h-full">
                        <Column field="title" header="Modül Adı" style={{ minWidth: '12rem' }} sortable></Column>
                        <Column field="settings.program.title" header="Program Adı" body={(rowData) => programTemplate(rowData)} style={{ minWidth: '12rem' }} sortable></Column>
                        <Column header="İşlemler" body={actionSubBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </Dialog>

                <Dialog
                    header={usersHeader}
                    visible={userDialogVisible}
                    style={{ width: '40vw' }}
                    modal
                    contentStyle={{ height: 'auto' }}
                    onHide={() => {
                        setUserDialogVisible(false);
                        setSelectedUser(null);
                    }}
                >
                    <DataTable dataKey="id" emptyMessage="Kullanıcı Bulunamadı" value={selectedCompany?.users || []} className="h-full" header={attachDialogHeaderTemplate}>
                        <Column field="name" header="İsim" sortable style={{ minWidth: '12rem' }}></Column>
                        <Column field="email" header="Email" sortable style={{ minWidth: '16rem' }}></Column>
                        <Column header="İşlemler" body={actionDetachUserTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    </DataTable>
                </Dialog>

                <AddProgramDialog visible={addProgramDialog} selectedModule={selectedModule} onHide={() => setAddProgramDialog(false)} onSubmit={navigateProgramPage} />

                <AttachModuleDialog visible={attachModuleDialog} onHide={() => setAttachModuleDialog(false)} onSubmit={attachModuleToCompany} />
                <DeleteModuleDialog
                    visible={deleteModuleDialog}
                    selectedModule={selectedModule}
                    onHide={() => {
                        setDeleteModuleDialog(false);
                        setSelectedModule(null);
                    }}
                />

                <AddDialog show={companyDialog} hideDialog={() => setCompanyDialog(false)} />
                <EditDialog
                    show={editDialog}
                    hideDialog={() => setEditDialog(false)}
                    selectedCompany={selectedCompany}
                    onEditCompany={() => {
                        setEditDialog(false);
                        setSelectedCompany(null);
                    }}
                />
                <DeleteDialog
                    show={deleteCompanyDialog}
                    companyId={selectedCompany?.id}
                    name={selectedCompany?.name}
                    hideDialog={() => setDeleteCompanyDialog(false)}
                    onDeleteCompany={() => {
                        setDeleteCompanyDialog(false);
                        setSelectedCompany(null);
                    }}
                />
            </div>
            <CommonDeleteDialog {...deleteDialogData} />
        </div>
    );
}
